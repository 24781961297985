const pdfinfo = {
    title: 'Account Information',
    desc: 'This account was generated by voilk.com, make sure you save this file, somewhere safe, or print the account credentials.',
    creator: 'voilk',
    details: [
        'Keep your keys safe',
        'We cannot recover lost passwords',
        'Print this file and put it somewhere safe',
        'Make sure no one has access to this file, or to your passwords',
        'If anyone got access to this file, you can lose your account',
        'If you have any questions write us an email support@voilk.com'
    ]
}

module.exports = {pdfinfo}