const list = `
hitbtc
bittrex
cryptopia
facebook
google
`
    .trim()
    .split('\n');

module.exports.list = list;