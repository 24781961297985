const faq = [
    [
        `What is Social.Voilk?`,
        `voilk is a place for the whole community to hangout, share content, learn and educate.`
    ],
    [
        `How does Voilk work?`,
        `Voilk.com is an application powered by the Voilk blockchain and VOILK cryptocurrency. 
        It read and write content to the Voilk blockchain,
         which stores the content in an immutable blockchain ledger, and rewards users for their contributions 
         with digital tokens called VOILK. Every 3 seconds, the Voilk blockchain mints new 0.501 VOILK tokens and adds them to a community's 'rewards pool'. (You can see how much VOILK is there in the reward pool by visiting properties tab on Voilk Explorer). These tokens are then awarded to users for their contributions, based on the likes/votes that their content receives. Users who hold more tokens in their account as 'Voilk Power' will get to decide where a larger portion of the rewards pool is distributed.`
    ],
    [
        `How does Voilk differ from other social media websites?`,
        `While most social media sites extract this value for the benefit of their shareholders, 
        Voilk believes that the users of the platform should receive the benefits 
        and rewards for their attention and the contributions they make to the platform. `
    ],
    [
        `What do I need to do in order to secure my account?`,
        `Unlike most social media websites, there is no way to recover your account if you lose your password / owner key! This is why it is extremely important that you save and backup your password somewhere safe. It is strongly recommended that you store an offline copy in case of a hard drive failure or other calamity. Consider digital offline storage, such as an external disk or flash drive, as well as printed paper stored in a fireproof safe. Use a safe deposit box for best redundancy. If you leak your private key to another user or a third-party website, that user or website will have full access to your account. This means they can steal it, as well as the funds inside of it. It is therefore not recommended to enter your private key information with any other user or third-party website. If you believe your account has been compromised, you should change your password/keys right away.Each account has multiple keys, which each have different levels of authority: owner, active, posting, and memo. The information on the different types of keys and their purposes, as well as more information on password and key security, can be found in the section on Security.`
    ],
    [
        `Does it cost anything to post, comment, or vote?`,
        `No. It is free to post, comment, and vote/like on content on voilk.com. You might even get paid for it!`
    ],
    [
        `Can I earn digital tokens on Voilk? How?`,
        `Yes, Depending on the likes you receive, you may get a portion of the 'rewards pool'. There are two ways you can earn VOILK tokens, 1) creating posts and getting likes on them (75% reward a post generates goes to the person who posted it) 2) Giving likes to other people's posts (25% of the reward a post receives, goes to people who liked it.) The reward amount will depend on the amount of Voilk Power you have.`
    ],
    [`Where do the tokens come from?`, `The Voilk blockchain continually creates new digital tokens to reward content creators and curators. Every 3 seconds to be exact. Some of the newly-created tokens are transferred to users who add value to Voilk.com by posting, commenting, and hitting likes on other people's posts. The remainder is distributed to the witnesses that power the blockchain. It is 85% to content creators and 15% to witnesses`],
    [`Where does the value come from?`, `At its root, Voilk is simply a monetory system. However, because this system is blockchain-based, the tokens it creates can be traded on the markets. People buy and sell these tokens, and many hold them in anticipation of it getting a higher value in future. The people who use the network, they add the value to the tokens basically.`],
    [`Why are people getting vastly different rewards?`, `As mentioned earlier, it all depends upon how many likes you get, and how much valueable the like was. That depends upon the Voilk Power of the user who liked your post. More value-able likes you get, more rewards you earn. It is best to have realistic expectations, without focusing on rewards when you are first starting out. Work on building a following, making connections, and developing a good reputation. Consistency will pay off in the long run.`],
    [`How do I create an account?`, `Creating an account is super easy with Voilk, you visit https://signup.voilk.com/register type a username, check to see if it is available, if it does hit the generate account button and wait for 30 seconds, a PDF file containing your account's access information will soon download.`],
    [`What information do I need to provide in order to create an account?`, `You frankly don't need to provide any information, we don't even ask for email address and/or phone numbers. We value anonimity and it is good idea to hide your personal information from the world, so that no one can use it against you.`],
    [`How much VOILK Power a new account has?`, `There is none, a new Account is nearly empty and it's good idea to buy some tokens to power it up, before you start using the platform. `],
    [`Why my account has a (-) symbol?`, `Free accounts contain (-) symbol, and yes it is annoying to people. Get a paid account if you don't want a (-) symbol in your username, it can cost anywhere between 5$ upto 250$ depending upon the value of the username.`],
    [`Do I need to provide my phone number or email address?`, `No, we don't require any email address or phone numbers to generate an account for you.`],
    [`How to buy a paid account?`, `A paid account, which does not have a (-) symbol in the username of it, can be purchased by contacting us via email.`],
    [`Am I allowed to create more than one account?`, `You are allowed to create more than one account, in case you lost your existing account, or you want to have separate accounts for separate things, but creating spam accounts you should not do.`],
    [`Can I change my username?`, `You cannot change your username, but if you don't like your username for some reason, you can create a new account`],
    [`Can I delete or deactivate my account?`, `Unfortunately you cannot delete or de-activate your account, since it's created on an immutable blockchain ledger and as a company we don't have control over it. any information that's written on the blockchain will exist, as long as the blockchain is alive.`],
    [`Is there an Etiquette Guide for Voilk?`, `You can checkout the FAQs or you can seek help from the community. `],
    [`Am I required to verify my identity?`, `No you are not required to verify your identity, you can hide your personal information from the world if you like.`],
    [`How do I upvote/like a post or comment?`, `To upvote a post or comment, click on the 'upvote/like' icon at the bottom of the post/comment`],
    [`What do the Home, New, Hot, Trending, and Promoted links show?`, `These are various ways to sort Voilk posts. Home - The most recent posts of the accounts you follow (your feed). New - Posts are sorted by the time posted, with newest first. Hot - Popular posts at the moment. Trending - Posts with the most amount of votes, stake-weighted, recently.`],
    [`What information is shown in my wallet?`, `Your wallet shows how many VOILK and Voilk Dollar tokens you have in your account. It shows how much Voilk Power it has, and how much VP is delegated. It also shows how many of your VOILK and Voilk Dollar tokens are being held in the savings account, which is a balance that is subject to 3 day withdraw waiting period. The wallet page shows any the progress of any Voilk Dollar to VOILK conversions as well as the status of a power down. It also shows an estimated value of all the tokens in your account, based on the recent market price of VOILK.`],
    [`How do I transfer my VOILK or Voilk Dollars into savings?`, `Your savings balance is VOILK and VSD tokens that are subject to 3 day withdraw waiting period. This is an extra security measure in case your account credentials are compromised. To transfer VOILK or VSD tokens into savings, click on the drop-down arrow next to VOILK or VOILK DOLLARS in your wallet, and select 'Transfer to Savings' `],
    [`How do I send money to another user?`, `From your wallet page, click the VOILK or Voilk Dollar balances with the down arrow next to them.In the drop-down menu, click 'Transfer'.Type the username of the account you want to send the VOILK or Voilk Dollars to. Double and triple check the spelling.Enter the amount of VOILK or Voilk Dollars to send.Enter a memo to go along with the transaction (optional).Click Submit.You will be prompted for your password. You will need to enter your master password or active key.`],
    [`Will I receive notifications when there is activity with my account?`, `When there is new activity in your feed, you receive a reply from another user, or there is a new transfer in your wallet, you will receive a notification in your account menu. It will show a little number showing the number of new notifications. Voilknetwork also allows you to subscribe to receive additional notifications when users mention you in a comment or post. Currently, We are in a process of implementing the feature in complete.`],
    [`What is shown in my profile?`, `At the top of your profile is your display name and reputation score. Below your display name is the number of followers you have, the number of posts and comments you have written, and the number of people you are following. It also shows the month and year when your account was created. You have the option to change your avatar and display name on the Settings page. There, you can set additional information such as 'about' information, your location, and add a link to a website of your choosing. You also have the option to set a cover image for your profile.You can view your own profile by clicking on the link to your Blog in your account menu.`],
    [`How do I change my avatar image and other profile information?`, `Your profile info, avatar image, and cover image are set in your Settings page. In order to update your avatar picture and cover image, you will need to host the images somewhere. This can be done by uploading it to a Voilknetwork comment or post, or using a third-party image hosting site such as Postimage. Once your image is uploaded, copy its URL and paste it into the 'Profile Picture URL' box for the avatar, or the 'Cover Image URL' box for the cover image. Then click the Update button and enter your password or active key.`],
    [`What is the recommend size for the cover image?`, `The cover image will be resized/scaled depending on the device being used. Therefore it is recommend to use an image that will still look good when cropped or resized. A 2048x512 image is the optimal size to work for most devices.`],
    [`How can I control whvoilk I see 'Not Safe For Work' (NSFW) content?`, `By default, content that users have tagged as 'NSFW' will be hidden, but a link will be shown to reveal the content.You can update your display preference with the Settings page so that NSFW content is always shown by default, remains hidden until clicked, or is completely hidden with no option to reveal.`],
    [`How do I search for content?`, `In the upper right corner of Voilknetwork, there is a magnifying glass search link where you can find posts using a keyword search. There is also an Explore link in the main menu, where you can browse through posts based on tags.`],
    [`Can I see which users I have muted?`, `Yes. This can be seen under the Settings page.`],
    [`Can I see which users have muted me?`, `Yes. This can be seen under the Settings page.`],
    [`Can I see the list of users I am following, and who is following me?`, `Yes. Find the options to see them on your profile page.`],
    [`What languages are supported?`, `You can make posts in any language you want, you just need the right keyboard on your devices.`],
    [`What can users post to Voilk?`, `Voilk is an open platform meant to host and welcome any legal content. Users can post anything they want, whvoilk it be phrases, quotes, blogs, anecdotes, photos, videos, memes, songs, and more. Be creative!`],
    [`What are the different choices for post rewards (50%/50%, Power Up 100%, Decline Payout)?`, `50%/50% - This rewards in half Voilk Power, and half liquid VOILK / Voilk Dollars. The ratio of liquid VOILK to Voilk Dollars rewarded is based on network conditions at the time of payout. This is the default payout option. Power Up 100% - This option rewards the post in 100% Voilk Power.Decline Payout - Use this option to receive no post rewards. Votes will affect the post's position on the trending ranking but no rewards are paid from Voilk's reward pool. Replies made to the post are still eligible for rewards.`],
    [`How do I add images and photos to my posts?`, `You can use https://images.voilk.com to upload images, or find an upload button along side your create a post page.`],
    [`How do I set the thumbnail image for my post?`, `The first image in the post will automatically be set as the thumbnail image.`],
    [`What is the recommend aspect ratio for thumbnail images?`, `The recommend aspect ratio for thumbnail images is 16x9.`],
    [`How do I add videos to my posts?`, `To add a YouTube, or Vimeo video to your blog post, simply paste the URL link to the video into the post.`],
    [`Is there a way I can make my images smaller?`, `Yes, but the picture must be resized before it is uploaded into the voilk.com editor. This can be done in your favorite photo editing software, or online by uploading to a third-party website that features editing such as imgur.com.`],
    [`What are tags?`, `Tags are a way to categorize your content, so that others can find it. The more relevant the tags are to the post, the more like-minded people will come across it.`],
    [`What tags should I use?`, `Try to use tags that are relevant to your post, and that will be popular for other people to browse. For example,'mytriptoalaska' may be relevant to your post, but readers are probably not going to go searching for that. Using 'travel' would be a better choice for a tag in this case.You can browse through commonly used tags using the 'Explore' link, in the main menu.Be mindful when choosing tags. If your tags aren’t related to your post, your post may get downvotes for mistagging.All tags must be lowercase letters. Spaces aren't allowed, but hyphenated words with a single dash are.`],
    [`How many tags can I use?`, `You can use up to 5 tags per post.`],
    [`Why is the 'Post' button grayed out?`, `A post must have a title, body, and at least one valid tag. If any of these are missing, then the 'Post' button will be disabled.`],
    [`How do I format text in Markdown?`, `For more advanced formatting, a guide describing the common markdown formatting syntax can be found here: https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet Markdown Cheatsheet`],
    [`How often can I post?`, `You are allowed to post almost as often as you like. Currently, posts must be spaced 5 minutes apart. However, the community may not find value in users that are posting too frequently. Keep in mind what your audience will be interested in viewing, so that you do not overwhelm your followers with too much content.`],
    [`How long can my post be?`, `Post sizes are limited to about 64,000 characters including formatting. This is ample for most posts. If writing blogs, consider how much people are willing to read at one time. If you make your posts too long, readers may lose interest which may affect the amount of upvotes and rewards you receive.`],
    [`If posting in a language other than English, how will I get recognized?`, `You can use language-specific tags to help you to reach the audience that speaks your language. Language-specific groups include: Chinese = cn German = deutsch Spanish = spanish Korean = kr Russian = ru French = fr Portuguese = pt`],
    [`Can I delete something I posted?`, `The blockchain will always contain the full edit history of posts and comments, so it can never be completely deleted. If you would like to update a post so that users cannot see the content via voilk.com, you can edit the post and replace it with blank content for as long as the post is active. After seven days, the post can no longer be edited.`],
    [`What does 'Promoting' a post do?`, `When you make a post, there is the option to promote it with Voilk Dollars. It will then show up in the “Promoted” tab. The order that it appears in the list depends on how much the post was promoted for. Posts with a higher promoted amount will be higher than posts with less. Voilk Dollars spent to promote a post are paid to the account @null, which nobody owns or controls. Once a user transfers VSD to @null, the Voilk blockchain removes them from the currency supply. You can promote your own posts, or posts that you like from other users.`],
    [`How do I promote a post?`, `At the bottom of each post is a button to 'Promote'. After clicking the button, type the number of Voilk Dollars that you want to spend and click “PROMOTE”. The operation will require your master password or active key.`],
    [`Can I earn digital tokens for commenting?`, `Yes, comments that are upvoted can earn rewards just like posts!`],
    [`How often can I comment?`, `There is a 20 second wait time in between comments to limit spam.`],
    [`Where do the new VOILK tokens come from?`, `Blockchains like Voilk and Bitcoin produce new tokens each time a block is produced. Unlike Bitcoin, where all of the new coins go to the block producers (called miners), the Voilk blockchain allocates a majority of the new tokens to a reward fund called the 'rewards pool'. The rewards pool gives users tokens for participating in the platform based on the value they add.`],
    [`How many new tokens are generated by the blockchain?`, `There are about 0.501 VOILK new tokens generated by the voilk Blockchain every 3 seconds, which will narrow down to 0.100 VOILK/3 seconds in a period of 20 years. blockchain reward is adjust after every 250,000 blocks. `],
    [`How are the new tokens distributed?`, `Every new 0.501 VOILK tokens is divided into two parts, 85% of which goes to community reward pool, which is then distributed to content creators, 15% goes to witnesses which are nodes of computers, that keep the voilk project up and running.`],
    [`Which exchanges are VOILK and VSD listed on?`, `VOILK and VSD are not listed on any exchanges, and we don't plan to list them any time soon, only way to buy/sell them is by contact us via email support@voilk.com`],
    [`What is the reward pool?`, `Every time a block is generated, it creates new VOILK coins, 85% of which is added to this reward pool, which is kind of like mutual fund, that belongs to everyone who is a user of voilk network. These get distributed to authors and curators for posting and liking the content.`],
    [`How is the reward pool split between authors and curators?`, `75% of the content Reward goes to content creator, remaining 25% goes to the curators (people who curated the content with their likes).`],
    [`Will the reward pool pay out more or less depending on who votes/likes?`, `There is a fixed amount of VOILK coins that gets added to the rewards pool each day. In the short term, the amount of coins that get paid out may be higher or lower depending on the amount of voting/liking activity, but over time it will pay out the full amount of rewards regardless of who liked/voted. Votes in Voilk are stake-weighted. Therefore voters with more Voilk Power/COINS have a greater influence over the allocation than voters with less VP, but their votes do not increase the amount of rewards in the rewards pool.`],
    [`Why do the earnings for my post go up or down?`, `Earning can go up or down, depending upon several factors, first being how many likes you are getting and how valuable those likes are, which depends upon the account's Voilk Power which liked your your post. remember the reward pool is distributed among members, depending upon the valuable likes, so there will be more posts coming after your post, and people might direct rewards to those posts as well. so the rewards keep changing, until the claim date is reached after 7 days.`],
    [`When can I claim my rewards?`, `After 7 days your earning is added to your account and you can claim it from there.`],
    [`What is the difference between VOILK, VOILK Power, and Voilk Dollars?`, `Voilk is a liquid, transferable token, that is produced by the blockchain every 3 seconds similar to Bitcoin, It can be converted into VOILK power. VOILK power is a non-transferable token, which decides how much influence you have over the voilk network, how much of the reward pool you control. It is less liquid, if a user wishes to convert it back to VOILK, it takes 365 days to convert back, however a part of it withdraws to VOILK daily. VSD is a Dollar pegged token, that means it is exactly equal to the US dollar with 1:1 ratio.`],
    [`How much VSD and VOILK were created at the time of start of the project?`, `20 M VSD, and 10 M VOILK were created at the time project was started.`],
    [`Does Voilk has that much USD available to back the VSD?`, `No, Voilk does not have the dollars available to back them, that is why circulating supply of VSD is much much smaller than actual supply, as users join the network to buy them for to make payments. They come out of cold storage to circulating supply, remember they are just numbers on a computer, if we don't get users to buy them and use them for payments.`],
    [`What happens, if all of 20 Million VSD is bought by the users?`, `If all the premined supply of VSD is circulated, it is possible for us to print as many VSD on demand. so we can maintain the peg with USD easily.`],
    [`Voilk is liable to pay me 1 USD against 1 VSD? whenever I want`, `Yes that is true, you can claim US dollars against the VSD you own anytime you like, minus the withdrawal fees ofc.`],
    [`What is delegated VOILK Power?`, `Users have the option to delegate Share Power to other users. When a user is delegated Share Power - their bandwidth, content votes, and curation rewards are calculated as if it were their own Share Power. Users are not able to power down or cash out delegated Share Power however, as it still belongs to the original owner. Most users will have a small amount of Share Power delegated to them by the Voilknetwork account after creating an account via voilk.com.Delegated Share Power shows up in a user's wallet below their actual Share Power balance in parentheses.`],
    [`What determines the price of VOILK?`, `The price of VOILK is based on the supply and demand of the token, as determined by buyers and sellers on the exchanges. It is similar to how the price of a commodity like gold is determined.`],
    [`How do I get more VOILK Power?`, `With VOILK tokens in your wallet, click 'Power Up' to turn them into Voilk Power. If you have Voilk Dollars, you can convert them to VOILK from your wallet, and then power up the VOILK. or you can just buy the VSD and convert it into VOILK for that you can contact us at support@voilk.com`],
    [`How long does it take VOILK or VOILK Power that I purchased to show up in my account?`, `Transactions on the Voilk blockchain typically only take about three seconds to process, but if you are purchasing the VSD it can take some time, since we manually process all orders.`],
    [`What is powering up?`, `Powering up - If you have VOILK tokens, you can Power Up to Voilk Power to get more voting influence on posts and comments. Having more Voilk Power also increases the amount of curation rewards and new coined tokens that you can earn. More VP also grants more influence on approving Voilk witnesses.`],
    [`What is powering Down?`, `Powering down - If you have Voilk Power, you can power down to turn it into liquid VOILK over a period of time. The system will transfer 1/365 of your Voilk Power to VOILK every day for 365 days, starting 1 day from the time it is started. However, you will lose your influence in the network proportionally to how much is powered down, so think about it carefully. Power downs can be stopped at any time.`],
    [`What do the dollar amounts for pending payouts represent?`, `Estimated value a post can earn, mostly payouts comes in pairs with VSD and VP, or VOILK and VP. and these are estimated values, they might differ greatly from actual value.`],
    [`Will 1 Voilk Dollar always be worth $1.00 USD?`, `Yes it will always be worth exactly 1 USD.`],
    [`Can I convert my VSD to VOILK?`, `You can use internal market to trade your VSD against VOILK, it's a market for users of voilknetwork to trade tokens between themselves.`],
    [`What can I do with my VOILK tokens?`, `Power up to VOILK power and earn content rewards, exchange them against VSD and withdraw them and buy whatever you want`],
    [`What can I do with my VSD tokens?`, `Since VSD is pegged with USD which is widely accept all around the world, so you can buy whatever you want with them.`],
    [`What is a MCOIN?`, `It is a user of measurement for VOILK POWER, MCOIN equals 1 Million VP/VOILK POWER/COINS`],
    [`Can I sell goods and services on Voilk?`, `There is no direct way of doing that, but it is possible to build your online store, and accept payments in VOILK or VSD`],
    [`How can I withdraw VSD coins?`, `You can withdraw them, by contacting us via email support@voilk.com, we manage transactions manually for the time being`],
    [`Am I being paid by Voilk Network?`, `No we don't pay you for creating content, or any actions you perform on the network. it is a reward system/social network/banking system where the users are in control, we only help you acheive that with the help of the software.`],
    [`How much are the transaction fees for sending tokens to other users?`, `There are no costs for making transactions, infact it is totally free to make transactions on the voilk network, Deposits and Withdrawals however can incur some fees.`],
    [`Are there fees for Powering Up, Powering Down, trading on the internal market, or converting VSD to VOILK?`, `No. None of these actions incur any fees.`],
    [`How long does it take to transfer VOILK or VSD tokens between users?`, `It only takes 3 seconds to make the transfers.`],
    [`What is my voting/liking power?`, `Voting/liking power is like an 'energy bar' in a computer game that goes down a little bit every time you vote. You start out with 100% voting power. Every time you vote, you will use a small amount of your voting power.As you use more of your voting power, your votes will carry less influence. A vote with 50% voting power left will be worth 1/2 as much as a vote cast with 100% voting power. Not to worry, the network recharges your voting power every day.`],
    [`How many times can I vote without depleting my voting power?`, `Every 100% vote you cast will use 1% of your remaining voting power. . You can vote more than 20 times per day, but each vote will be worth less, and it will take longer to reach full voting power again.`],
    [`Can I vote with less than 100% of my voting strength?`, `New users can only upvote and downvote with 100% voting strength. Once you reach about 500 Voilk Power, you will see a vote slider appear when you vote. You can use the slider to adjust the weight of your vote, between 1% and 100% voting strength. Voting with less than 100% voting weight will use up less voting power, but it will also have less of an influence on the post or comment's rewards.`],
    [`Where can I check my voting power?`, `You can view your current voting power using the blockchain explorer tool such as https://explorer.voilk.com/@youraccount.`],
    [`What determines how much of the curation reward goes to the author versus curators?`, `The rewards are allocated so that 75% of the payout goes to the author of the post/comment, and 25% goes to the curator. Of the 25% that goes to the curator, that portion will be split between the author and the curator if the curator votes within the first 30 minutes. The split of the 25% between the author and curator during the first 30 minutes is calculated linearly based on the time the vote is cast.If a post is upvoted the moment of posting, 100% of the curation reward goes to the author. At 3 minutes, 90% goes to the author and 10% to the curator.At 15 minutes it's a 50/50 split. At 27 minutes, 10% goes to the author and 90% to the curator. If a post is upvoted 30 min after posting, 100% of the curation reward goes to the curator.`],
    [`Can I get curation rewards for upvoting comments?`, `Yes. You can earn curation rewards from upvoting both posts and comments!`],
    [`Do I get curation rewards for downvoting posts or comments?`, `No. Since downvoting reduces the rewards on a post/comment, it does not earn curation rewards.`],
    [`Why don't my upvotes have an effect on a post's rewards?`, `A user with more SP is going to have a larger influence on the rewards than users with less SP. One vote from a user with a lot of SP can often have more of an effect than 100 votes from users with a small amount of SP. Even though your vote may not have an immediate effect, when it gets added in along with all the other votes at the end of the payout period, it can still affect the payout. It may also cause more users to vote on the post too, because they saw that you upvoted it - so your votes can have an indirect effect on the payout this way.`],
    [`Is there a way to make my votes count for more?`, `Yes. The more Voilk Power you have, the more influence your votes will have. The platform does not require that anybody purchase SP in order to participate, and there are many users who have earned a lot of Voilk Power without spending any of their own money. You have the option of purchasing more Voilk Power through your Voilknetwork wallet.`],
    [`What are the valid reasons for downvoting?`, `Users are allowed to downvote for any reason that they want. There are many users in the community who recommend only using the downvote on posts that are abusive. It is up to you if you want to follow this etiquette.`],
    [`Does a downvote mean that I did something wrong?`, `Just because you received a downvote does not mean that you did something wrong. The downvoting person may have just been voting to reallocate the rewards in a way that they felt was more beneficial to the other active posts in the platform. Often users will leave a comment explaining why they downvoted, but sometimes they might not. If they left a reason, it is up to you to determine if you did anything wrong, and if there is anything you want to change.`],
    [`Will a downvote hurt my reputation?`, `Not necessarily`],
    [`What is the difference between a downvote and a flag?`, `With the current implementation, there is no difference between a downvote and a flag. They are treated the same at the blockchain level.`],
    [`What is considered spam or abuse?`, `Asking for money, views, upvotes, follows, or revoilks. Leaving nearly identical or materially similar comments on multiple posts. Comments that are unrelated to the topic of discussion. Sending unsolicited links or requests to users via wallet memos. Posts that require upvotes to enter or play in a contest or game. Sending users a link to your blog or a post if it is not relevant to the conversation. Posts or comments that include little or nothing more than an offer to trade follows or upvotes. Using tags that are unrelated to the post.Threatening users with any type of physical violence.Not citing sources when using someone else’s material. Posting ‘not safe for work’ content without using the “nsfw” tag. Selling or offering to buy votes/revoilks/follows, or schemes that facilitate this. Scams or Fraudulent offers.`],
    [`What are Voilk’s policies on plagiarism?`, `If you are posting plagiarized or copied content, you can get in legal trouble for violating copyright laws. Plagiarized posts and spam are seen as abuse and will be downvoted by community members. If you are posting or using someone else’s content, you must ensure that you have the rights to use the content, and properly reference the sources where you got the material from.`],
    [`Is it okay to use random pictures from the internet?`, `If you are using an image that is not your own, make sure you are allowed to use the image, and cite the source of the image. Using random pictures from the internet without giving credit is discouraged. You may, however, use photos from “free image” websites such as Pexels.com or Pixabay.com. All photos on Pexels and Pixabay are free for personal and commercial use.`],
    [`Where do I report a post or comment that contains plagiarism, spam, or abuse?`, `You can report any abusive content to our Discord Group. or make a post about it and use #report_abuse Tag`],
    [`What is Reputation?`, `Every user has a reputation score next to their name. The reputation score is one way Voilknetwork measures the amount of value you have brought to the community. It is also a mechanism that is designed to help reduce abuse of the Voilknetwork platform. Your reputation goes up when accounts vote on your content. Getting downvoted by someone with a higher reputation can push your reputation down and make your posts less visible.Users with a lower reputation score are unable to affect your reputation.`],
    [`How is the Reputation score measured?`, `Every new user starts off with a reputation score of 25. The reputation score is based off of a log10 system, which means that a score of 40 is about 10x better than a score of 30.`],
    [`How do I improve my reputation score?`, `Every time another user upvotes one of your posts or comments, it increases your reputation score. The more Voilk Power that the voter has, the larger the effect is. The best way to earn upvotes is by adding value to the Voilknetwork community.`],
    [`What causes my reputation score to go down?`, `The only way for your reputation score to go down is to be downvoted by another user. Not all downvotes will cause a reputation loss though. Downvotes from users with a lower reputation score than you will not hurt your score.If your post or comment that was downvoted still received more upvotes than downvotes (weighted by SP), then the net effect on your reputation score will still be positive.`],
    [`Why does my reputation score matter?`, `A reputation score is one way Voilknetwork measures the amount of value you have brought to the community. In real estate, they say there are three variables of the utmost importance: location, location, location. On Voilknetwork, those things are: reputation, reputation, reputation. It’s not to say other variables aren’t important, but reputation will be an enormous factor in your level of success.Many Voilkians glance at users’ reputation scores when deciding which articles to read because they know higher reputation scores means it is much more likely quality content. Furthermore, the higher your rep, the more effect your vote will have on the reputation of others.It is worth noting that if your reputation score goes below 0, Voilknetwork will hide your posts and comments making it very difficult to gain monetary rewards and followers. This incentivizes online etiquette and respect for your fellow Voilkians.`],
    [`What is Revoilking?`, `This is like reblogging or sharing posts on other platforms. Once you revoilk a post it will appear in your feed and in your followers' feeds as if you had posted it yourself. Use it conservatively and with caution. It is great to want to share content you like and appreciate with people you follow, but you don't want to overwhelm your followers either.`],
    [`Can I share on other social media?`, `Yes you can use the share button to share on Facebook, Twitter or LinkedIn. You are welcome to post your Voilknetwork links on other websites and social media sites.`],
    [`What is a blockchain?`, `A blockchain is a public ledger of all transactions ever executed. All of the transactions and data are stored in a distributed database. Each time the database is updated, all of updates are done togvoilk in a batch called a 'block'. Each time a new block is produced/added, it is appended on to all of the previous blocks - hence the name blockchain.`],
    [`What is the Voilk blockchain?`, `The Voilk blockchain is the publicly accessible distributed database, which records all posts and votes, and distributes the rewards across the network. It is where all of the text content and voting data is stored, and it is where all of the reward calculations and payouts are performed.`],
    [`How does bandwidth work on the Voilk blockchain?`, `Since transacting on the Voilk blockchain has zero fees, bandwidth rate-limiting is employed to safeguard the blockchain from spam attacks. Everything action that you take on the blockchain will consume a small amount of bandwidth. This includes posting, commenting, voting, transferring tokens, etc. Viewing content does not consume bandwidth. Every user has a limited amount of bandwidth to use each week. The more transactions a user does, the less bandwidth they will have left (until it recharges). Users with more Voilk Power will have a higher bandwidth allowance. Normally everyone's bandwidth allowance is quite high, and users are able to use the network freely without any interruptions. Sometimes when the blockchain becomes busy however (due to heavy use), everyone's individual allowances may go down until the network becomes less busy. You can check how much bandwidth you currently have based on the current limit at: https://explorer.voilk.com/@youraccount If users are below their bandwidth limit, they will be unable to transact with the blockchain until their bandwidth recharges or their limit is raised. If you get an error that you have exceeded your bandwidth allowance, it is normally best to just wait and try again later (when it is less busy). Usually if you wait and try again later, the transaction will likley go through. If you are unable to transact for extended periods of time, or you are frequently running into bandwidth limits, then you will either need to reduce your usage to stay within your limit, or purchase more Voilk Power for your account.`],
    [`What is the difference between Voilk and Voilk Network?`, `Voilk is the name of the blockchain that stores all of the data and transactions, and processes all of the events that take place. VOILK is also a name for the system’s value token (currency). Voilknetwork is a front end web interface to interact with the blockchain, and view the blockchain data. Voilknetwork is also the name of the company that owns and operates the website voilk.com`],
    [`How is Voilk different from Bitcoin?`, `On a technical level, the two networks rely on the same model of a blockchain, but are built upon different technologies and codebase. Voilk is based on a new state-of-the-art blockchain technology called Graphene, which uses 'witnesses' instead of 'miners' to produce blocks. The 'delegated proof of stake' model of using witnesses instead of miners allows for greater efficiency in block production. With BTC, 100% of the new coins that are created are allocated to block producers (miners). With the Voilk blockchain, only 15% of the new coins are paid to block producers (witnesses). The other 85% of new VOILK coins are awarded to content producers, curators, and Voilk Power holders.`],
    [`What is the difference between Proof of Work, Proof of Stake, and Delegated Proof of Stake?`, `Proof of work - Miners solve a complex mathematical problem. The miner that solves the problem first adds the block to the blockchain. The network rewards the miner for doing so. Proof of stake - Requires ownership, or stake, in the cryptocurrency. The more tokens you own, the more block creation power you have. Benefits: eliminates the need for expensive mining rigs, runs on a tiny fraction of the power, and it requires block producers to have a stake in the network. Delegated proof of stake - Block-creating accounts, called witnesses, are collectively approved by Voilk stakeholders. Instead of relying on proof of work to find blocks, the Voilk network actively schedules these accounts to improve the time between blocks to 3 seconds.`],
    [`How often does the Voilk blockchain produce a new block?`, `The Voilk blockchain schedules witnesses to produce a new block every 3 seconds. 21 witness nodes produce 21 blocks in each 63-second round.`],
    [`Can I mine VOILK?`, `You can yes, if you have sufficient knowledge on how to work with VOILK nodes, and how to run a witness node for the network, that's how you can earn witness rewards, you can also call them mining rewards.`],
    [`Who is the Founder of Voilk?`, `Bilal Haider (https://voilk.com/@bilal) is the Founder of Voilk Project.`],
    [`Am I allowed to use the Voilk and Voilk logos?`, `You are free to use, any voilk projects but you cannot use the logos for your own projects. since they represent the company uniquely on the internet.`],
    [`Did Voilk 'pre-mine' tokens?`, `Yes, there were about 20 Million VSD and 10 Million VOILK tokens premined.`],
    [`Why were the tokens premined?`, `To provide users with stable value of VSD against USD, a decent amount of VSD coins was premined. So that you can always get exactly 1 USD against your 1 VSD. There are maintanence costs involved in running the network, for that purpose 10 million VOILK tokens were premined which we will sell to users without hurting the value too much. periodically`],
    [`VSD can be produced on demand?`, `Since voilk is also a bank, VSD can be printed on demand, so if you wish to run a deposit/withdrawal service for people in your country, you can place an order to print VSD and we can print them for you. You can choose your own deposit and withdrawal fees for your customers.`],
    [`What is the Voilk Privacy Policy?`, `You can read our privacy policy here https://voilk.com/privacy.html`],
    [`How can I keep my Voilk account secure?`, `Save your master password and keep it somewhere safe. Only log into your account using the key with the appropriate permissions for what you are doing: Posting key for every day logins Active key when necessary for transfers, power ups, etc. Master password or owner key when changing the password Again, save your master password and keep it safe! If logging in with your post key, make sure you don't overwrite or misplace your original master password.It is not recommended to share your password or keys with any third party site`],
    [`Why should I be careful with my master password?`, `The master password is used to derive all keys for your account, including the owner key. If someone has access to your master password, they can steal your account and all of the tokens in it.`],
    [`Why is the master password a long string of gibberish?`, `The password has to be long and random for maximum account security.`],
    [`What are my different keys for?`, `Posting key - The posting key allows accounts to post, comment, edit, vote, revoilk, and follow or mute other accounts. Most users should be logging into Voilknetwork every day with the posting key. You are more likely to have your password or key compromised the more you use it so a limited posting key exists to restrict the damage that a compromised account key would cause. Active key - The active key is meant for more sensitive tasks such as transferring funds, power up/down transactions, converting Voilk Dollars, voting for witnesses, updating profile details and avatar, and placing a market order. Memo key - Currently the memo key is not used. Owner key - The owner key is only meant for use when necessary. It is the most powerful key because it can change any key of an account, including the owner key. Ideally it is meant to be stored offline, and only used to recover a compromised account.`],
    [`What do I do if I lost my password/keys?`, `There is no way to recover your account if you lose your password or owner key! Because your account has real value, it is very important that you save your master password somewhere safe where you will not lose it. It is strongly recommended that you store an offline copy of your password somewhere safe in case of a hard drive failure or other calamity. Consider digital offline storage, such as an external disk or flash drive, as well as printed paper. Use a safe deposit box for best redundancy.`],
    [`Are my VOILK and Voilk Dollar tokens insured in the event of a hack or if someone takes over my account?`, `No, liquid tokens can not be taken back if stolen or sent to the wrong account. If your tokens are in Voilk Power, it is impossible for a hacker to take out more than 1/365 per day. If your tokens are in savings, there is a three-day wait period for them to become transferable.`],
    [`What should I do if I discover that someone hacked my account?`, `We cannot do anything if your account is hacked. It's your responsibity to keep your passwords safe and secure.`],
    [`How does the stolen account recovery process work?`, `We cannot recover accounts.`],
    [`How do I report a security vulnerability?`, `If you find a security issue please report the details to support@voilk.com.`],
    [`Are the Voilk blockchain and Voilk.com code open-source?`, `Yes. Both the Voilk blockchain and voilk.com are open-source projects. Developers should however avoid the use of the term 'Voilknetwork' in their own products, and instead refer to the Voilk Blockchain or Voilk Platform. `],
    [`Is there a Github page for the softwares VOILK makes?`, `https://github.com/voilknetwork`],
    [`What is available for developers interested in Voilk?`, `There are documents available to give them a start, but you should be able to work it out`],
    [`What are Voilk witnesses?`, `The Voilk blockchain requires a set of people to create blocks and uses a consensus mechanism called delegated proof of stake, or DPOS. The community elects 'witnesses' to act as the network's block producers and governance body. There are 20 full-time witnesses, producing a block every 63-second round. A 21st position is shared by the backup witnesses, who are scheduled proportionally to the amount of stake-weighted community approval they have. Witnesses are compensated with Voilk Power for each block they create.`],
    [`How can I vote for witnesses?`, `You should always look for how witnesses are contributing the project overall. choose to only for those who are actively contributing, avoid voting for those who are just there to claim the rewards. Visit https://voilk.com/~witnesses.`],
    [`How many witnesses can I vote for?`, `Each account can vote for up to 30 witnesses.`],
    [`What third-party tools are there for Voilk?`, `There are many open source tools, you can find them on our github page.`],
    [`Where can I ask for help if my question was not answered here?`, `You can always write an email to support@voilk.com`]
]


module.exports = { faq }