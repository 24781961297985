import React, { Component } from 'react';

class Projects extends Component {
    state = {  }
    render() { 
        return ( <p>
            Projects
        </p> );
    }
}
 
export default Projects;