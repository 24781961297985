import React, { Component, Fragment } from 'react';
import Questions from './Questions';

class FaqPanel extends Component {
    state = {  }
    render() { 
        return ( 
           <Questions />
         );
    }
}
 
export default FaqPanel;